"use strict";
exports.__esModule = true;
exports.renderExpression = void 0;
function renderExpression(expression) {
    switch (expression.type) {
        case "application":
            var leftSide = void 0;
            if (expression.left.type !== "function") {
                leftSide = renderExpression(expression.left);
            }
            else {
                leftSide = "(" + renderExpression(expression.left) + ")";
            }
            // I have no idea whether the rendering of the right side is valid.
            var rightSide = void 0;
            if (expression.right.type === "variable") {
                rightSide = renderExpression(expression.right);
            }
            else {
                rightSide = "(" + renderExpression(expression.right) + ")";
            }
            return "" + leftSide + rightSide;
        case "function":
            return "\u03BB" + expression.argument + "." + renderExpression(expression.body);
        case "variable":
            return expression.name;
    }
}
exports.renderExpression = renderExpression;
