"use strict";
exports.__esModule = true;
exports.LambdaExecutionTimeoutError = exports.LambdaLexingError = exports.LambdaSyntaxError = exports.purgeAstCache = exports.equal = exports.replace = exports.tokenize = exports.leftmostOutermostRedex = exports.toNormalForm = exports.eReduce = exports.bReduce = exports.getFreeVars = exports.renderAsChurchBoolean = exports.renderAsChurchNumeral = exports.renderExpression = exports.parseExtendedSyntax = exports.parseTerm = void 0;
// ---
// lol how do i even do named exports
var parser_1 = require("./parser");
exports.parseTerm = parser_1.parseTerm;
exports.parseExtendedSyntax = parser_1.parseExtendedSyntax;
var renderer_1 = require("./renderer");
exports.renderExpression = renderer_1.renderExpression;
var churchPrimitives_1 = require("./churchPrimitives");
exports.renderAsChurchNumeral = churchPrimitives_1.renderAsChurchNumeral;
exports.renderAsChurchBoolean = churchPrimitives_1.renderAsChurchBoolean;
var util_1 = require("./util");
exports.getFreeVars = util_1.getFreeVars;
exports.purgeAstCache = util_1.purgeAstCache;
var operations_1 = require("./operations");
exports.bReduce = operations_1.bReduce;
exports.eReduce = operations_1.eReduce;
exports.replace = operations_1.replace;
var normalize_1 = require("./normalize");
exports.toNormalForm = normalize_1.toNormalForm;
exports.leftmostOutermostRedex = normalize_1.leftmostOutermostRedex;
var lexer_1 = require("./lexer");
exports.tokenize = lexer_1.tokenize;
var equality_1 = require("./equality");
exports.equal = equality_1.equal;
var errors_1 = require("./errors");
exports.LambdaSyntaxError = errors_1.LambdaSyntaxError;
exports.LambdaLexingError = errors_1.LambdaLexingError;
exports.LambdaExecutionTimeoutError = errors_1.LambdaExecutionTimeoutError;
