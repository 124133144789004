"use strict";
exports.__esModule = true;
exports.renderAsChurchBoolean = exports.renderAsChurchNumeral = void 0;
var equality_1 = require("./equality");
var parser_1 = require("./parser");
var cannonize_1 = require("./cannonize");
// TODO: do the inverse of these -- generation of church primitives
function renderAsChurchNumeral(uncannonized) {
    var expression = cannonize_1.cannonize(uncannonized);
    if (expression.type !== "function") {
        return undefined;
    }
    var outerName = expression.argument;
    var inner = expression.body;
    if (inner.type !== "function") {
        return undefined;
    }
    var innerName = inner.argument;
    function countLevels(wrapperName, targetName, piece) {
        if (piece.type === "variable") {
            if (piece.name !== targetName) {
                return undefined;
            }
            else {
                return 0;
            }
        }
        if (piece.type === "application") {
            if (piece.left.type !== "variable" || piece.left.name !== wrapperName) {
                return undefined;
            }
            else {
                var nextLevel = countLevels(wrapperName, targetName, piece.right);
                if (nextLevel === undefined) {
                    return undefined;
                }
                else {
                    return nextLevel + 1;
                }
            }
        }
        return undefined;
    }
    return countLevels(outerName, innerName, inner.body);
}
exports.renderAsChurchNumeral = renderAsChurchNumeral;
var churchTrue = parser_1.parseTerm("λab.a");
var churchFalse = parser_1.parseTerm("λab.b");
function renderAsChurchBoolean(expression) {
    if (equality_1.equal(expression, churchTrue)) {
        return true;
    }
    if (equality_1.equal(expression, churchFalse)) {
        return false;
    }
    return undefined;
}
exports.renderAsChurchBoolean = renderAsChurchBoolean;
