"use strict";
exports.__esModule = true;
exports.equal = void 0;
var cannonize_1 = require("./cannonize");
function sameType(a, b) {
    return a.type === b.type;
}
// Equality up to alpha conversion.
function rEqual(a, b) {
    switch (a.type) {
        // if it's free, we should hope they're the same.
        // if it's not free, we should hope that whatever renaming scheme already converted it
        case "variable":
            return sameType(a, b) && a.name === b.name;
        case "application":
            return (sameType(a, b) && rEqual(a.left, b.left) && rEqual(a.right, b.right));
        case "function":
            return sameType(a, b) && rEqual(a.body, b.body);
    }
}
function equal(a, b) {
    var cA = cannonize_1.cannonize(a);
    var cB = cannonize_1.cannonize(b);
    return rEqual(cA, cB);
}
exports.equal = equal;
