"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.LambdaExecutionTimeoutError = exports.LambdaLexingError = exports.LambdaSyntaxError = void 0;
var LambdaSyntaxError = /** @class */ (function (_super) {
    __extends(LambdaSyntaxError, _super);
    function LambdaSyntaxError(message) {
        var _this = _super.call(this, "Syntax Error: " + message) || this;
        _this.name = "LambdaSyntaxError";
        return _this;
    }
    return LambdaSyntaxError;
}(Error));
exports.LambdaSyntaxError = LambdaSyntaxError;
var LambdaLexingError = /** @class */ (function (_super) {
    __extends(LambdaLexingError, _super);
    function LambdaLexingError(message) {
        var _this = _super.call(this, "Lexing Error: " + message) || this;
        _this.name = "LambdaLexingError";
        return _this;
    }
    return LambdaLexingError;
}(Error));
exports.LambdaLexingError = LambdaLexingError;
var LambdaExecutionTimeoutError = /** @class */ (function (_super) {
    __extends(LambdaExecutionTimeoutError, _super);
    function LambdaExecutionTimeoutError(message) {
        var _this = _super.call(this, message) || this;
        _this.name = "LambdaExecutionTimeoutError";
        return _this;
    }
    return LambdaExecutionTimeoutError;
}(Error));
exports.LambdaExecutionTimeoutError = LambdaExecutionTimeoutError;
