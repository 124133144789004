"use strict";
exports.__esModule = true;
exports.leftmostOutermostRedex = exports.toNormalForm = void 0;
var operations_1 = require("./operations");
var errors_1 = require("./errors");
function toNormalForm(expression, depthOverflow) {
    if (depthOverflow === void 0) { depthOverflow = 1000; }
    var count = 0;
    var current;
    var reduced = expression;
    do {
        current = reduced;
        reduced = leftmostOutermostRedex(current);
        count++;
        if (count >= depthOverflow) {
            throw new errors_1.LambdaExecutionTimeoutError("Normal form execution exceeded. This expression may not have a normal form.");
        }
    } while (reduced !== undefined);
    return current;
}
exports.toNormalForm = toNormalForm;
function leftmostOutermostRedex(expression) {
    if (operations_1.bReducable(expression)) {
        return operations_1.bReduce(expression);
    }
    if (expression.type === "function") {
        var res = leftmostOutermostRedex(expression.body);
        if (res === undefined) {
            return undefined;
        }
        else {
            return {
                type: "function",
                argument: expression.argument,
                body: res,
            };
        }
    }
    if (expression.type === "variable") {
        return undefined;
    }
    if (expression.type === "application") {
        var leftReduced = leftmostOutermostRedex(expression.left);
        if (leftReduced !== undefined) {
            return {
                type: "application",
                left: leftReduced,
                right: expression.right,
            };
        }
        var rightReduced = leftmostOutermostRedex(expression.right);
        if (rightReduced !== undefined) {
            return {
                type: "application",
                left: expression.left,
                right: rightReduced,
            };
        }
        return undefined;
    }
}
exports.leftmostOutermostRedex = leftmostOutermostRedex;
