"use strict";
exports.__esModule = true;
exports.parseExtendedSyntax = exports.parseTerm = exports.parseExpression = exports.parseStatement = void 0;
var lexer_1 = require("./lexer");
var errors_1 = require("./errors");
// this one'll be a better entry point
function parseStatement(tokenStream) {
    // could handle errors better-- this one just will say unexpected token
    // when it reaches a nonstandard assignment token.
    if (tokenStream.length >= 2) {
        var first = tokenStream[0]; //to satisfy the typechecker
        if (first.type === "identifier" && tokenStream[1].type === "assignment") {
            var lhs = first.value;
            var rhs = parseExpression(tokenStream.splice(2));
            return { type: "assignment", lhs: lhs, rhs: rhs };
        }
    }
    return parseExpression(tokenStream);
}
exports.parseStatement = parseStatement;
function parseExpression(tokenStream) {
    var _a;
    if (tokenStream.length === 0) {
        throw new errors_1.LambdaSyntaxError("Empty Expression");
    }
    var _b = popExpression(tokenStream), expression = _b[0], rest = _b[1];
    var applications = [expression];
    while (rest.length !== 0) {
        _a = popExpression(rest), expression = _a[0], rest = _a[1];
        applications.push(expression);
    }
    // For left-associativity.
    return applications.reduce(function (prev, cur) { return ({
        type: "application",
        left: prev,
        right: cur,
    }); });
    // And reduce to produce the application
}
exports.parseExpression = parseExpression;
function popExpression(tokenStream) {
    // 3 cases. 1:
    var nextToken = tokenStream[0];
    switch (nextToken.type) {
        case "identifier":
            return [
                { type: "variable", name: nextToken.value },
                tokenStream.slice(1),
            ];
        case "lambda":
            // scan forward to find the dot, add in arguments
            if (tokenStream.length < 2) {
                throw new errors_1.LambdaSyntaxError("Unexpected end of lambda");
            }
            var dotPosition = 1;
            while (tokenStream[dotPosition].type !== "dot") {
                if (tokenStream[dotPosition].type !== "identifier") {
                    throw new errors_1.LambdaSyntaxError("Non-identifier in argument stream");
                }
                dotPosition++;
                if (dotPosition >= tokenStream.length) {
                    throw new errors_1.LambdaSyntaxError("Unexpected end of lambda");
                }
            }
            var args = tokenStream.slice(1, dotPosition);
            if (args.length === 0) {
                throw new errors_1.LambdaSyntaxError("Bad number of arguments");
            }
            var childExp = parseExpression(tokenStream.slice(dotPosition + 1));
            var exp = args.reduceRight(function (acc, cur) { return ({
                type: "function",
                argument: cur.value,
                body: acc,
            }); }, childExp);
            return [
                exp,
                [],
            ];
        case "openParen":
            var depth = 0;
            var splitPoint = -1;
            for (var i = 0; i < tokenStream.length; i++) {
                var cur = tokenStream[i];
                if (cur.type === "openParen") {
                    depth++;
                }
                if (cur.type === "closeParen") {
                    depth--;
                }
                if (depth === 0) {
                    splitPoint = i + 1;
                    break;
                }
            }
            if (splitPoint < 0) {
                throw new errors_1.LambdaSyntaxError("Unmatched Paren");
            }
            return [
                parseExpression(tokenStream.slice(1, splitPoint - 1)),
                tokenStream.slice(splitPoint),
            ];
        default:
            throw new errors_1.LambdaSyntaxError("Unexpected Token");
    }
}
// We should rename these to be better.
function parseTerm(str) {
    return parseExpression(lexer_1.tokenize(str));
}
exports.parseTerm = parseTerm;
// This isn't understood by most helper functions, as it's an extension of the lambda calculus.
// TODO: make this more well supported.
function parseExtendedSyntax(str) {
    return parseStatement(lexer_1.tokenize(str));
}
exports.parseExtendedSyntax = parseExtendedSyntax;
